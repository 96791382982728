import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  const { i18n } = useTranslation();
  const [termsContent, setTermsContent] = useState('');
  const [contentVersion, setContentVersion] = useState(0);

  useEffect(() => {
    fetch(`/privacy_policy_${i18n.language}.md`)
      .then(response => response.text())
      .then(text => {
        setTermsContent(text);
        setContentVersion(prevVersion => prevVersion + 1); // Zmiana stanu przy każdym ładowaniu
      })
      .catch(err => console.error("Failed to load privacy policy:", err));
  }, [i18n.language, contentVersion]); // Dodanie contentVersion do zależności

  return (
    <div className="privacy-policy-container">
      <div className="box-column">
        <ReactMarkdown>{termsContent}</ReactMarkdown>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
