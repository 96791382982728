// components/Feature.js
import React from 'react';
import './Features.css';
import { useTranslation } from 'react-i18next';

function Feature() {
  const { t } = useTranslation();

  return (
    <div className="feature-container">
      <div className="feature">
      <h2>{t('feTitle1')}</h2>
      <div className="fe-text">
        {t('feature1')}
        </div>
        <div className='separator'></div>
        <h2>{t('feTitle4')}</h2>
        <div className="fe-text">
        {t('feature4')}
        </div>
        <div className='separator'></div>
        <h2>{t('feTitle7')}</h2>
        <div className="fe-text">
        {t('feature7')}
        </div>
        </div>
        <div className="feature">
      <h2>{t('feTitle2')}</h2>
      <div className="fe-text">
        {t('feature2')}
        </div>
        <div className='separator'></div>
        <h2>{t('feTitle5')}</h2>
        <div className="fe-text">
        {t('feature5')}
        </div>
        <div className='separator'></div>
        <h2>{t('feTitle8')}</h2>
        <div className="fe-text">
        {t('feature8')}
        </div>
        </div>
        <div className="feature">
      <h2>{t('feTitle3')}</h2>
      <div className="fe-text">
        {t('feature3')}
        </div>
        <div className='separator'></div>
        <h2>{t('feTitle6')}</h2>
      <div className="fe-text">
        {t('feature6')}
        </div>
        <div className='separator'></div>
        <h2>{t('feTitle9')}</h2>
      <div className="fe-text">
        {t('feature9')}
        </div>
        </div>
    </div>
  );
}

export default Feature;
