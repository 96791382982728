import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import googleLogo from '../google.png';
import './Footer.css';

function Footer() {
    const { t, i18n } = useTranslation();

    const [contactContent, setContactContent] = useState('');

    useEffect(() => {
        // Załaduj treść Markdown na podstawie aktualnego języka
        fetch(`/contact_info_${i18n.language}.md`)
            .then(response => response.text())
            .then(text => setContactContent(text))
            .catch(err => console.error("Failed to load contact info:", err));
    }, [i18n.language]); // Zależność od aktualnego języka

    return (
        <footer className="footer">
            <div className="footer-column">
                <h2>{i18n.t('footer.contactTitle')}</h2>
                <div className='footer-text'>
                    <ReactMarkdown>{contactContent}</ReactMarkdown>
                </div>
            </div>
            <div className="footer-column">
            <h2>{i18n.t('footer.menuTitle')}</h2>
            <div className='footer-text'>
            <ul>
                <li><Link to="/">{t('footer.home')}</Link></li>
                    <li><Link to="/Features">{t('footer.Features')}</Link></li>
                    <li><Link to="/Privacy-policy">{t('footer.privacyPolicy')}</Link></li>
                    <li><Link to="/Company">{t('footer.CompanyPage')}</Link></li>
                </ul>
                </div>
            </div>
            <div className="footer-column">
            <h2>{i18n.t('footer.notaTitle')}</h2>
            <div className='footer-text'>
                <div className='lang-box'>
                <LanguageSwitcher />
                </div>
                <div className='google'>
                <a href="https://play.google.com/store/apps/details?id=com.hc.hyperbook" target="_blank" rel="noopener noreferrer">
                <img src={googleLogo} alt="Google Play" className="google-play-logo" />
                </a>
                </div>
                </div>
            </div>
        </footer>
    );
}

function LanguageSwitcher() {
    const { i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    return (
        <div>
            <button onClick={() => changeLanguage('en')}>EN</button>
            <button onClick={() => changeLanguage('pl')}>PL</button>
        </div>
    );
}

export default Footer;
