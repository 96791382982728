import React from 'react';
import './HeroSection.css';
import { ReactComponent as Home } from '../home.svg';
import { ReactComponent as Details } from '../details.svg';
import { useTranslation } from 'react-i18next';

function HeroSection() {
    const { t } = useTranslation();

    return (
        <div className="hero-section">
            <div className="hero-description">
                <h1>{t('heroTitle')}</h1>
                <h2>{t('heroDescription')}</h2>
            </div>
            <div className="hero-row">
                <div className="hero-column">
                    <div className="hero-bg">
                        <Home className="home-image" />
                    </div>
                </div>
                <div className="hero-column">
                    <h2>{t('heroTitle1')}</h2>
                    <div className="hero-text">
                        <p>{t('heroText1')}</p>
                    </div>
                    <div className="hero-text">
                        <p>{t('heroText2')}</p>
                    </div>
                </div>
            </div>
            <div className="hero-row">
                <div className="hero-column">
                    <h2>{t('heroTitle2')}</h2>
                    <div className="hero-text">
                        <p>{t('heroText3')}</p>
                    </div>
                    <div className="hero-text">
                        <p>{t('heroText4')}</p>
                    </div>
                    <div className="hero-text">
                        <p>{t('heroText5')}</p>
                    </div>
                </div>
                <div className="hero-column">
                    <div className="hero-bg1">
                        <Details className="details-image" />
                    </div>
                </div>
            </div>
            {/* ... */}
        </div>
    );
}

export default HeroSection;
