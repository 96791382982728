import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import './CompanyPage.css';

function CompanyPage() {
    const { i18n } = useTranslation();
    const [companyContent, setCompanyContent] = useState('');
    const [contentVersion, setContentVersion] = useState(0); // Dodano stan dla wersji treści

    useEffect(() => {
      fetch(`/company_info_${i18n.language}.md`)
        .then(response => response.text())
        .then(text => {
          setCompanyContent(text);
          setContentVersion(prevVersion => prevVersion + 1); // Zmiana wersji treści
        })
        .catch(err => console.error("Failed to load company information:", err));
    }, [i18n.language, contentVersion]); // Dodano contentVersion do listy zależności

    return (
      <div className="company-page-container">
        <div className="box-column">
          <ReactMarkdown>{companyContent}</ReactMarkdown>
        </div>
      </div>
    );
  }

export default CompanyPage;