import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import HeroSection from './components/HeroSection';
import Features from './components/Features';
import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/Footer';
import CompanyPage from './components/CompanyPage';

function App() {
  return (
    <Router>
      <Navbar />
      <Banner />
      <Routes>
        <Route path="/" element={<HeroSection />} />
        <Route path="/Features" element={<Features />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/company" element={<CompanyPage />} />
        {/* Dodaj więcej ścieżek w zależności od potrzeb */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
