import React, { useState } from 'react';
import './Navbar.css';
import { ReactComponent as Logo } from '../logo.svg';
import menu from '../menu.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen);
  };


  return (
    <nav className="navbar">
      <div className="logo-container">
        {/* Link do nawigacji do strony głównej wraz z logo */}
        <Link to="/" className="logo-link">
        <Logo className="logo" alt="Logo" />

        </Link>
      </div>
      <button className="hamburger" onClick={toggleMenu}>
        <img src={menu} alt="Menu" />
      </button>
      <div className={`menu-container ${isMenuOpen ? 'open' : ''}`}>
      <div className="menu">
  <Link to="/" onClick={toggleMenu}>{t('navbar.home')}</Link>
  <Link to="/Features" onClick={toggleMenu}>{t('navbar.Features')}</Link>
  <Link to="/Privacy-policy" onClick={toggleMenu}>{t('navbar.privacyPolicy')}</Link>
  <Link to="/Company" onClick={toggleMenu}>{t('navbar.CompanyPage')}</Link>
  {/* Dodaj więcej linków w razie potrzeby */}
</div>

      </div>
    </nav>
  );
}

export default Navbar;
