import React from 'react';
import './Banner.css';
import { ReactComponent as BannerImage } from '../banner.svg';

function Banner() {
  return (
    <div className="slider">
      <div className="slide">
        <BannerImage alt="Banner" className="banner-img" />
        {/* Możesz dodać więcej slajdów tutaj */}
      </div>
      {/* Tu można dodać kontrolki slidera w przyszłości */}
    </div>
  );
}

export default Banner;
